<template>
  <a :href="link" :class="`button button_${color}`" data-qa="apply-tariff-upgrade">
    <slot>{{ $i18n('upgrade') }}</slot>
  </a>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import mailto from '@/util/mailto-helper';

export default {
  name: 'ApplyButton',
  props: {
    color: {
      type: String,
      default: 'green'
    },
    mailText: {
      type: String,
      default: ''
    },
    reason: {
      type: String,
      default: ''
    },
    reasonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    link() {
      const accountingEmail = appConfig.get('accountingEmail');
      const orgName = window.Organization?.account.name;
      if (this.mailText) {
        return mailto(this.mailText, accountingEmail, {
          orgName
        });
      }
      const tariffName = window.LicenseInfo?.current_license.tariff_group.name;
      // eslint-disable-next-line
      const reason = this.reasonText || this.$trlMessage(this.reason);

      const subjectAppend = reason
        ? `: ${reason} (${orgName} ${this.$i18n('now')} ${tariffName})`
        : ` (${orgName})`;
      return mailto('promo.tariff_upgrade', accountingEmail, {
        subject: subjectAppend
      });
    }
  }
};
</script>
<i18n lang="json">
{
  "upgrade": {
    "ru_RU": "Заявка на смену тарифа",
    "en_US": "Apply to change your plan"
  },
  "now": {
    "ru_RU": "сейчас",
    "en_US": "now"
  }
}
</i18n>
