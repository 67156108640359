<template>
  <div :class="$style.wrapper" @mousemove="handleMousemove" @mouseenter.capture="handleMouseenter">
    <dynamic-scroller
      ref="scroller"
      :items="items"
      :min-item-size="minItemSize"
      :key-field="keyField"
      :page-mode="!!pageMode"
      :prerender="$options.prerender"
      :buffer="pageMode ? 500 : 200"
      skip-hover
      list-tag="ul"
      :list-class="$style.list"
      item-tag="li"
      :item-class="$style.item"
      data-qa="scroller"
    >
      <template #before>
        <slot name="before" />
      </template>
      <template #default="{ item }">
        <slot :item="item" :index="itemMap.get(item)" />
      </template>
      <template #after>
        <slot name="after" />
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import { DynamicScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { getOverflowAncestors } from '@floating-ui/dom';

import { prerender } from './constant';

export default {
  name: 'VirtualScroller',
  components: { DynamicScroller },
  prerender,
  props: {
    items: {
      type: Array,
      required: true
    },
    keyField: {
      type: String,
      default: 'id'
    },
    minItemSize: {
      type: [Number, String],
      required: true
    },
    pageMode: {
      type: [Boolean, Object],
      default: false
    }
  },
  emits: ['scroll', 'mousemove', 'item-mouseenter'],
  computed: {
    itemMap() {
      // библиотека не поддерживается, а тут баг образовался, в некоторых случаях индекс приходит undefined, поэтому считаем самостоятельно
      const map = new WeakMap();
      this.items.forEach((item, index) => {
        map.set(item, index);
      });
      return map;
    }
  },
  mounted() {
    const list = this.$refs.scroller.$refs.scroller;
    this.scrollElement = getOverflowAncestors(list.$refs.wrapper)[0];
    this.scrollElement.addEventListener('scroll', this.handleScroll, {
      passive: true
    });
  },
  beforeDestroy() {
    this.scrollElement.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.$emit('scroll');
    },
    handleMousemove(event) {
      if (this.$refs.scroller?.$refs.scroller.$refs.wrapper.contains(event.target)) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseenter(event) {
      if ('index' in event.target.dataset) {
        this.$emit('item-mouseenter', parseInt(event.target.dataset.index));
      }
    },
    scrollToIndex(index) {
      if (!this.$refs.scroller) {
        return;
      }
      const list = this.$refs.scroller.$refs.scroller;
      const scrollElement = getOverflowAncestors(list.$refs.wrapper)[0];
      const { start, end } = list.getScroll();
      const listHeight = end - start;
      const { accumulator, size } = list.sizes[index];
      if (this.pageMode) {
        const scrollTop = this.pageMode ? scrollElement.scrollTop : 0;
        const listTop = list.$el.getBoundingClientRect().top;
        const scrollElementTop = scrollElement.getBoundingClientRect().top;
        const { bottom = 0, top = 0 } = this.pageMode;
        if (accumulator + bottom > end) {
          return (scrollElement.scrollTop =
            scrollTop + listTop + accumulator - window.innerHeight + bottom);
        }
        if (accumulator - size - top < scrollElementTop - listTop) {
          return (scrollElement.scrollTop =
            scrollTop - scrollElementTop + listTop + accumulator - size - top);
        }
        return;
      }

      if (accumulator > end) {
        return (scrollElement.scrollTop = accumulator - listHeight);
      }

      if (accumulator - size < start) {
        return (scrollElement.scrollTop = accumulator - size);
      }
    }
  }
};
</script>

<style module>
.list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.item {
}
.wrapper {
  display: contents;
}
</style>

<i18n lang="json">{}</i18n>
