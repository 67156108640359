<template>
  <div :class="$style.hint" v-html="hintHtml" />
</template>

<script>
import { DomPurify } from '@/services/dom-purify/dom-purify';

export default {
  name: 'VacancyRequestApprovalHint',
  props: {
    hint: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hintHtml() {
      if (!this.hint) {
        return '';
      }
      return DomPurify.sanitize(this.hint, {
        ALLOWED_TAGS: ['br', 'b', 'strong', 'p', 'a', 'ul', 'ol', 'li'],
        ADD_ATTR: ['target', 'href', 'rel'],
        USE_PROFILES: false
      });
    }
  }
};
</script>

<style module>
.hint {
  color: #999;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    li::marker {
      font-variant-numeric: lining-nums;
    }
  }

  ul {
    padding-left: 13px;
  }
  ol {
    padding-left: 22px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  ul + *,
  ol + * {
    margin-top: 10px;
  }

  * + ul,
  * + ol {
    margin-top: 5px;
  }

  a {
    padding: 3px 0;
    color: currentColor;
    &:hover {
      color: $redColor;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
