<template>
  <div data-qa="vacancy-request-view" :class="$style.vacancyRequestView">
    <template v-if="vacancyRequestSchema">
      <base-loader :loading="loading">
        <template #loader>
          <content-loader />
        </template>
        <template #default>
          <header-block :title="vacancyRequest.position">
            <template #default="{ titleClass, title }">
              <div :class="$style.header" data-qa="popup-layout-heading">
                <span data-qa="title" :class="[titleClass, $style.title]">
                  {{ title }}
                </span>
                <a :href="settingsHref" target="_blank" :class="$style.constructorLink">
                  <base-icon type="settings" width="16" height="16" />
                  {{ $i18n('go_to_constructor') }}
                </a>
              </div>
            </template>
          </header-block>
          <vjsf-form
            :schema="processedSchema.schema"
            :ui-schema="processedSchema.ui_schema"
            :form-data="vacancyRequest.values"
            mode="inline"
            :class="$style.form"
          />
          <portal v-if="files" :to="mainPortal">
            <file-attachment-list :files="files" view-mode :class="$style.attachments" />
          </portal>
          <portal :to="sidePortal">
            <base-delimiter :top="20" :bottom="20" />
            <div data-qa="side-info" :class="$style.info">
              <watcher-info :vacancy-request="vacancyRequest" />
              <state
                v-for="(state, index) in states"
                :key="index"
                :first="index === 0"
                :state="state"
              />
              <more-button
                v-if="isShowMoreButtonVisible"
                :class="$style.moreButton"
                size="full"
                data-qa="more-button"
                @click="showAllStates"
              >
                {{ $trlMessage('button.show_more') }}
              </more-button>
              <approval-summary v-if="isApproved" :vacancy-request="vacancyRequest" />
            </div>
          </portal>
          <layout-row border-before :class="$style.footer">
            <div>
              <buttons-line>
                <template #default="slotProps">
                  <slot name="footer" v-bind="slotProps">
                    <base-button kind="light" data-qa="close" @click="handleCancel">
                      {{ $trlMessage('button.close') }}
                    </base-button>
                  </slot>
                </template>
              </buttons-line>
            </div>
          </layout-row>
        </template>
      </base-loader>
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { Portal } from 'portal-vue';
import { VacancyRequestStatus } from '@/types/vacancy-request-status';
import { userPermissions } from '@/services/config/user-permissions';
import BaseButton from '@/components/button/button.vue';
import BaseLoader from '@/components/loader/base-loader';
import BaseDelimiter from '@/components/delimiter/delimiter';
import BaseIcon from '@/components/icon';
import ContentLoader from '@/components/loader/content-loader';
import HeaderBlock from '@/components/layout/header';
import ButtonsLine from '@/components/layout/buttons-line';
import LayoutRow from '@/components/layout/row';
import MoreButton from '@/components/more-button/more-button.vue';
import VjsfForm from '@/components/vjsf/vjsf-view';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';
import FileAttachmentList from '@/components/ui/file-attachment-list/file-attachment-list.vue';
import VacancyRequestFormLayout, {
  VACANCY_REQUEST_MAIN_PORTAL,
  VACANCY_REQUEST_SIDE_PORTAL
} from '../../components/vacancy-request-form-layout';
import CompensationTemplate from '@/components/hf/request-list-item/widgets/compensation-template';
import ApprovalSummary from './approval-summary.vue';
import State from './state.vue';
import WatcherInfo from './watcher-info.vue';

const STATES_PAGE_LIMIT = 5;

export default {
  name: 'VacancyRequestView',
  components: {
    ApprovalSummary,
    BaseButton,
    BaseDelimiter,
    BaseIcon,
    BaseLoader,
    ButtonsLine,
    ContentLoader,
    FileAttachmentList,
    HeaderBlock,
    LayoutRow,
    MoreButton,
    Portal,
    State,
    VjsfForm,
    WatcherInfo
  },
  props: {
    vacancyRequest: {
      type: Object,
      default: null
    },
    vacancyRequestSchema: {
      type: Object,
      default: null
    },
    loading: Boolean
  },
  emits: ['cancel', 'more'],
  setup(props, { emit }) {
    const isAllStatesShown = ref(false);
    const showAllStates = () => {
      isAllStatesShown.value = true;
    };

    const isRejected = computed(
      () => props.vacancyRequest.status === VacancyRequestStatus.REJECTED
    );
    const isApproved = computed(
      () => props.vacancyRequest.status === VacancyRequestStatus.APPROVED
    );
    const states = computed(() => {
      const result = (props.vacancyRequest?.states || []).filter(
        (state) => !isRejected.value || state.status
      );
      if (isAllStatesShown.value) {
        return result;
      }
      return result.slice(0, STATES_PAGE_LIMIT);
    });
    const isShowMoreButtonVisible = computed(
      () => !isAllStatesShown.value && props.vacancyRequest?.states?.length > STATES_PAGE_LIMIT
    );

    const vacancyRequestIdRef = computed(() => {
      if (!isRejected.value) {
        return props.vacancyRequest.id;
      }
      // если заявка отклонена, то в этом компоненте нет её id-шника
      // (он оммитится для логики "отправить ещё раз"
      // но его можно получить из массива states
      const id = (props.vacancyRequest.states ?? []).find(
        (state) => state.vacancy_request
      )?.vacancy_request;
      return id;
    });

    const files = computed(() => props.vacancyRequest?.files || []);

    return {
      vacancyRequestId: vacancyRequestIdRef,
      canReceiveVacancyRequests: userPermissions.get('can_receive_vacancy_requests'),
      isWatcher: userPermissions.isWatcher,
      isRejected,
      isApproved,
      states,
      files,
      isShowMoreButtonVisible,
      showAllStates,
      handleCancel: () => emit('cancel'),
      settingsHref: `/settings/orgs/${userPermissions.get('nick')}/vacancy-request`
    };
  },
  computed: {
    mainPortal: () => VACANCY_REQUEST_MAIN_PORTAL,
    sidePortal: () => VACANCY_REQUEST_SIDE_PORTAL,
    processedSchema() {
      if (!this.vacancyRequestSchema) {
        return this.vacancyRequestSchema;
      }

      // Выкидываем поле position, т.к. оно показывается в заголовке модалки
      const schema = VacancyRequestHelpers.removePosition(this.vacancyRequestSchema);
      const uiSchema = setObjectTemplateComponents(this.vacancyRequestSchema.ui_schema, {
        [CompensationTemplate.name]: CompensationTemplate
      });
      uiSchema['ui:ObjectFieldTemplate'] = VacancyRequestFormLayout;
      if (uiSchema['applicants_to_hire']) {
        uiSchema['applicants_to_hire']['ui:options'].breakAfter = false;
      }

      return {
        ...this.vacancyRequestSchema,
        schema,
        ui_schema: uiSchema
      };
    }
  }
};
</script>

<i18n lang="json">
{
  "go_to_constructor": {
    "ru_RU": "Настроить поля формы",
    "en_US": "Customise form fields"
  }
}
</i18n>

<style module>
.vacancyRequestView {
  background: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;

  .title {
    @mixin ellipsis;
  }

  .constructorLink {
    flex: none;
    color: $lightTextColor;
    text-decoration: none;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    &:hover {
      color: $redColor;
    }
  }
}

.attachments {
  margin-left: -9px;
}

.info {
  overflow-wrap: break-word;
  word-break: normal;
}

.moreButton {
  padding-top: 10px;
}

.form form > :global(.form-group) {
  margin-bottom: 0;
}

.footer {
  padding: 20px 0;
}
</style>
