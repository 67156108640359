<template>
  <base-icon
    v-if="loading"
    type="spinner"
    data-qa="attachment-loader"
    :class="[$style.icon, $style.spinner]"
  />
  <base-attachment
    v-else-if="file.id || hasError"
    :has-error="hasError"
    :can-remove="!viewMode"
    @remove="$emit('remove', file.id)"
  >
    <template #title>
      <a
        v-if="viewMode"
        data-qa="files-name"
        target="_blank"
        :href="file.url"
        :class="$style.link"
        :title="caption"
      >
        <base-icon type="clip" :class="$style.icon" />{{ caption }}
      </a>
      <div v-else data-qa="files-name" :class="$style.iconWithCaption">
        <base-icon type="clip" :class="$style.icon" />
        <span :class="$style.caption" v-html="caption" />
      </div>
    </template>
  </base-attachment>
</template>

<script>
import BaseIcon from '@/components/icon';
import BaseAttachment from '@/components/hf/base-attachment/base-attachment';

export default {
  name: 'FileAttachment',
  components: { BaseIcon, BaseAttachment },
  props: {
    file: {
      type: Object,
      required: true
    },
    viewMode: Boolean,
    loading: Boolean
  },
  emits: ['remove'],
  computed: {
    hasError() {
      return Boolean(this.file.error);
    },
    caption() {
      if (this.file.error) {
        return this.file.error.message?.match('Bad content type')
          ? this.$trlMessage('file.error.attach.type')
          : this.$trlMessage('file.error.attach');
      }
      return this.file.name;
    }
  }
};
</script>

<style module>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 1s infinite linear;
}

.link {
  text-decoration: none;
  color: var(--file-attachment-link-color, $linkColor);
  max-width: 100%;
}

.link:hover {
  color: $redColor;
}

.link .icon {
  margin: -3px -1px 0 0;
}

.icon {
  height: 32px;
  width: 32px;
  color: $vantaBlackColor;
}

.iconWithCaption {
  display: flex;
  align-items: center;
  min-width: 0;

  .icon {
    flex: none;
  }

  .caption {
    @mixin ellipsis;
  }
}
</style>

<i18n lang="json">{}</i18n>
