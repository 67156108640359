<template>
  <div>
    <base-dropdown @change="handleChangeDropdownState">
      <template #default="{ toggle, shown }">
        <slot name="button" :on-toggle="toggle" :show="shown"></slot>
      </template>

      <template #content="{ hide }">
        <form @submit.prevent="handleSubmit(hide)">
          <!-- eslint-disable vue/no-restricted-syntax -->
          <dropdown-menu-layout
            size="larger"
            :title="
              $trlMessage(initialHref ? 'wysiwyg.edit_link.title' : 'wysiwyg.insert_link.title')
            "
          >
            <!-- eslint-enable vue/no-restricted-syntax -->
            <template #main>
              <label for="wyswyg_link_href" class="form-group__label">
                {{ $trlMessage('wysiwyg.edit_link.href') }}
              </label>
              <base-input id="wyswyg_link_href" v-model="href" focus />
            </template>
            <template #footer>
              <base-button type="submit" :disabled="!normalizedHref">{{
                $trlMessage('button.save')
              }}</base-button>
              <base-button kind="light" @click="handleCancel(hide)">{{
                $trlMessage('button.cancel')
              }}</base-button>
              <base-button
                v-if="initialHref"
                :class="$style.button"
                ghost
                kind="danger"
                @click="handleRemove(hide)"
              >
                {{ $trlMessage('button.remove') }}
              </base-button>
            </template>
          </dropdown-menu-layout>
        </form>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown';
import BaseInput from '@/components/input/input.vue';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';

export default {
  name: 'VLink',
  components: {
    BaseButton,
    BaseDropdown,
    BaseInput,
    DropdownMenuLayout
  },
  props: {
    initialHref: {
      type: String,
      default: undefined
    }
  },
  emits: ['change'],
  data() {
    return {
      href: this.initialHref
    };
  },
  computed: {
    normalizedHref() {
      return (this.href ?? '').trim();
    }
  },
  methods: {
    handleChangeDropdownState() {
      this.href = this.initialHref;
    },
    handleSubmit(hideLinkDropdown) {
      let href = this.normalizedHref;
      if (href.includes('://') === false) {
        href = 'https://' + href;
      }
      this.$emit('change', href);
      hideLinkDropdown();
    },
    handleRemove(hideLinkDropdown) {
      this.$emit('change', null);
      hideLinkDropdown();
    },
    handleCancel(hideLinkDropdown) {
      hideLinkDropdown(event);
    }
  }
};
</script>

<style module>
.button {
  margin-left: auto;
}
</style>

<i18n lang="json">{}</i18n>
